import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { Link } from "gatsby";

const Page = () => (
  <Layout>
    <SEO title="Programming projects" />
    <h1>React is fun!</h1>
    <ul>
      <li>
        <Link to="/weather">Weather Checker</Link>
      </li>
      <li>
        <Link to="/stopwatch">Stopwatch</Link>
      </li>
      <li>
        <Link to="/countdown-timer">Countdown Timer</Link>
      </li>
      <li>
        <Link to="/unclickable-button">Unclickable button</Link>
      </li>
      <li>
        <Link to="/food-world-cup">Food World Cup</Link> (in progress)
      </li>
      <li>
        <Link to="/exercise-timer">Self-Exercise Timer</Link> (in progress)
      </li>
      <li>
        <Link to="/tic-tac-toe">Tic-Tac-Toe</Link>
      </li>

      <li>
        <Link to="/connect4">Connect 4</Link>
      </li>

      <li>
        <Link to="/first-letter-last-letter-game">
          First Letter Last Letter Game
        </Link> (in progress)
      </li>
    </ul>
  </Layout>
);

export default Page;
